import React from 'react'
import cx from 'classnames'

import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BannerListDraggable from '../components/BannerListDraggable'
import TableBanner from '../components/TableBanner'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 400,
    maxWidth: 400,
    margin: 5
  },
  containerDraggable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const ListPub = (props) => {
  const { banners, setBannersData, loading } = props

  const classes = useStyles()
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <TableBanner banners={banners} setBannersData={setBannersData} loading={loading} />
      </div>
      <div className={cx(classes.container, loading && classes.containerDraggable)} >
        {loading ? <CircularProgress size={24} />
          : <BannerListDraggable banners={banners} setBannersData={setBannersData} loading={loading} />}
      </div>
    </div>
  )
}
