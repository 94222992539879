import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AdvertisingIcon from '../assets/icons/icons8-publicidad-64.png'

export const sections = [
  {
    id: 'Inicio',
    icon: <HomeIcon />,
    path: '/',
    active: false
  },
  {
    id: 'Publicidades',
    icon: <img src={AdvertisingIcon} className='MuiSvgIcon-root' alt='Anuncios' />,
    path: '/publicidades',
    active: true
  },
  {
    id: 'Administrar Usuarios',
    icon: <PeopleAltIcon />,
    path: '/users',
    active: false
  },
  {
    id: 'Salir',
    icon: <ExitToAppIcon />,
    path: '/logout',
    active: true
  }
]
