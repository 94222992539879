import React from 'react'

import { useHistory } from 'react-router-dom'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import TiposPub from './TiposPub'
import NewPubForm from './NewPubForm'
import BannerListDraggable from './BannerListDraggable'
import PageLoading from './PageLoading'

import { NOTIF_PUB_TEXTO_IMAGEN, NOTIF_PUB_IMAGEN } from '../consts/'

import { bannersActions } from '../actions/bannersActions'
import { userActions } from '../actions/userActions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 5,
    minHeight: 350
  },
  stepper: {
    padding: 9,
    backgroundColor: '#fafafa'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#707070'
  },
  finish: {
    minHeight: 350,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function getSteps () {
  return [
    'Selecciona el estilo del anuncio',
    'Cargar contenido',
    'Ordenar anuncios'
  ]
}

function getStepContent (step, setTipoPub, tipoPub, newBanner, setNewBanner, banners, setBannersData) {
  switch (step) {
    case 0:
      return <TiposPub
        setTipoPub={setTipoPub}
        newBanner={newBanner}
        setNewBanner={setNewBanner}
      />
    case 1:
      return <NewPubForm
        tipoPub={tipoPub}
        newBanner={newBanner}
        setNewBanner={setNewBanner}
      />
    case 2:
      return <BannerListDraggable
        step={3}
        banners={banners}
        newBanner={newBanner}
        setBannersData={setBannersData}
        setNewBanner={setNewBanner}
      />
    default:
      return null
  }
}

export default function NewPubStepper (props) {
  const { banners, setBannersData } = props
  const initialNewBanner = {
    notif_id: null,
    titulo: null,
    contenido: null,
    imagen: null,
    imagen_url: '',
    estilos: {
      titulo: '#fff',
      contenido: '#44fff6',
      background: '#1211117a'
    },
    fec_ini: new Date(),
    fec_fin: null,
    tipo_notif: null,
    orden: banners.length + 1,
    activo: true,
    descripcion: ''
  }
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorsFetch, setErrorsFetch] = React.useState(null)
  const [activeStep, setActiveStep] = React.useState(0)
  const [tipoPub, setTipoPub] = React.useState()
  const [errors, setErrors] = React.useState({
    error: false,
    title: '',
    text: '',
    icon: '',
    showCancelButton: ''
  })
  const [newBanner, setNewBanner] = React.useState(initialNewBanner)

  const steps = getSteps()

  const handleNext = async () => {
    if (activeStep === 1) {
      if (!newBanner.descripcion) {
        setErrors({
          ...errors,
          error: true,
          title: 'Oops...',
          text: 'No se pueden guardar anuncios sin descripcion.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK'
        })
      } else {
        if (tipoPub !== NOTIF_PUB_IMAGEN) {
          if (newBanner.contenido) {
            if (tipoPub === NOTIF_PUB_TEXTO_IMAGEN) {
              if (!newBanner.imagen && !newBanner.imagen_url) {
                setErrors({
                  ...errors,
                  error: true,
                  title: 'Atencion!',
                  text: 'Deseas guardar este anuncio sin una imagen de fondo?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Si, confirmar!'
                })
              } else {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
              }
            } else {
              setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
          } else {
            setErrors({
              ...errors,
              error: true,
              title: 'Oops...',
              text: 'No se pueden guardar anuncios sin contenido.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'OK'
            })
          }
        } else {
          if ((tipoPub === NOTIF_PUB_IMAGEN) && (!newBanner.imagen && !newBanner.imagen_url)) {
            setErrors({
              ...errors,
              error: true,
              title: 'Atencion!',
              text: 'Deseas guardar este anuncio sin una imagen de fondo?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Si, confirmar!'
            })
          } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          }
        }
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
    if (activeStep === steps.length - 1) {
      setIsLoading(true)
      setErrorsFetch(null)
      if (await userActions.refreshToken()) {
        const newBannerCreated = await bannersActions.create(newBanner)
        if (newBannerCreated.ok) {
          await Promise.all(banners.map(async item => {
            await bannersActions.updateBanner({ ...item, style: JSON.stringify(item.style) })
          }))
          /* **** */
          setBannersData([])
          // Utilizando useEffect se actualizan los banners autamagicamente luego de cargar nuevos
          setNewBanner(initialNewBanner)
          setIsLoading(false)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se ha creado un nuevo anuncio correctamente',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          window.alert('Ocurrio un error al crear el anuncio')
        }
      }
    }
  }

  const handleBack = () => {
    setNewBanner(initialNewBanner)
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setNewBanner(initialNewBanner)
    setActiveStep(0)
  }

  React.useEffect(() => {
    if (errors.error) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: errors.title,
        text: errors.text,
        icon: errors.icon,
        showCancelButton: errors.showCancelButton,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: errors.confirmButtonText,
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value && errors.icon === 'warning') {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
      })
    }
    if (errorsFetch) {
      window.alert(errorsFetch)
    }
  }, [errors, errorsFetch])

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <React.Fragment>
            {isLoading
              ? <PageLoading />
              : <React.Fragment>
                <div className={classes.finish}>
                  <Typography variant='h6' component='div' className={classes.instructions}>
                  Has completados todos los pasos - Que deseas hacer ahora?
                  </Typography>
                </div>
                <Button onClick={handleReset} className={classes.button}>
              Carga nuevo
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => history.push('/')}
                  className={classes.button}
                >
                  {'Volver al inicio'}
                </Button>
              </React.Fragment>
            }

          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography component='div' className={classes.instructions}>
              {getStepContent(activeStep, setTipoPub, tipoPub, newBanner, setNewBanner, banners, setBannersData)}
            </Typography>
            <React.Fragment>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Atrás
              </Button>
              <Button
                disabled={
                  (activeStep === 0 && tipoPub === undefined)
                }
                variant='contained'
                color='primary'
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
              </Button>
            </React.Fragment>
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  )
}
