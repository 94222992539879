import React, { useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { CircularProgress, LinearProgress, Typography } from '@material-ui/core'

import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { bannersActions } from '../actions/bannersActions'
import { NOTIF_PUB_TEXTO, NOTIF_PUB_TEXTO_IMAGEN } from '../consts/'

export default function TableBanner (props) {
  const { banners, setBannersData, loading } = props
  const handleDeleteBanner = idBanner => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Si, elimínalo!'
    }).then(async (result) => {
      if (result.value) {
        const result = Array.from(banners)
        var removeIndex = banners.map(function (item) { return item.notif_id }).indexOf(idBanner)
        result.splice(removeIndex, 1)
        const resp = await bannersActions.deleteBanner(idBanner)
        if (resp) {
          setBannersData(result)
        } else {
          window.alert('ocurrio un error en la eliminacion')
        }
      }
    })
  }
  const handleChangeActive = name => async event => {
    setBannersData(banners => (
      banners.map(banner => (banner.notif_id === name
        ? Object.assign(banner, { activo: event.target.checked })
        : banner
      ))
    ))
    const index = banners.findIndex(banner => banner.notif_id === name)
    await bannersActions.updateBanner({ ...banners[index], activo: event.target.checked })
  }

  const columns = [
    { name: 'id', label: 'ID', options: { display: false } },
    { name: 'descripcion', label: 'Descripción', options: { filter: true, sort: true } },
    { name: 'orden', label: 'Relevancia', options: { filter: true, sort: true, display: false } },
    { name: 'tipoPub', label: 'Tipo de anuncio', options: { filter: true, sort: true, display: false } },
    { name: 'dateInit', label: 'Vigente desde', options: { filter: true, sort: true } },
    { name: 'dateFinish', label: 'Vigente hasta', options: { filter: true, sort: true } },
    { name: 'active',
      label: 'Activo?',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Switch
              size='small'
              checked={value}
              onChange={handleChangeActive(tableMeta.rowData[0])}
              value={tableMeta.rowData[0]}
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )
        }
      }
    },
    { name: 'Eliminar',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton aria-label='delete' onClick={() => handleDeleteBanner(tableMeta.rowData[0])}>
              <DeleteIcon fontSize='small' color='secondary' />
            </IconButton>
          )
        }
      }
    }
  ]

  const [data, setData] = React.useState([])

  useEffect(function () {
    const data = []
    banners.map(anuncio => (
      data.push({
        id: anuncio.notif_id,
        descripcion: anuncio.descripcion,
        orden: anuncio.orden,
        tipoPub: anuncio.tipo_notif === NOTIF_PUB_TEXTO_IMAGEN ? 'Texto e imagen'
          : anuncio.tipo_notif === NOTIF_PUB_TEXTO ? 'Solo texto' : 'Solo imagen',
        dateInit: moment(anuncio.fec_ini).format('DD/MM/YYYY'),
        dateFinish: anuncio.dateFinish ? moment(anuncio.fec_fin).format('DD/MM/YYYY') : null,
        active: anuncio.activo
      })
    ))
    setData(data)
  }, [banners])

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    responsive: 'scrollMaxHeight',
    textLabels: {
      body: {
        noMatch: loading ? <LinearProgress variant='query' color='secondary' /> : 'Lo sentimos, no se encontraron anuncios coincidentes',
        toolTip: 'Sort',
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        viewColumns: 'Ver Columnas',
        filterTable: 'Filtrar'
      },
      filter: {
        all: 'Todos',
        title: 'Filtros',
        reset: 'Restablecer'
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
      },
      selectedRows: {
        text: 'fila(s) seleccionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar Filas Seleccionadas'
      }
    }
  }
  return <MUIDataTable
    title={
      <Typography variant='subtitle1'>Lista de anuncios
        {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
      </Typography>
    }
    data={data}
    columns={columns}
    options={options}
  />
}
