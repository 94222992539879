import React, { createContext, useState, useEffect } from 'react'
export const Context = createContext()

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    return window.sessionStorage.getItem('token')
  })
  const [loadingBanner, setLoadingBanner] = useState(false)

  useEffect(function () {
    if (!isAuth) {
      setIsAuth(false)
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('refresh')
    }
  }, [isAuth])

  const value = {
    isAuth,
    activateAuth: token => {
      window.sessionStorage.setItem('token', token)
      setIsAuth(true)
    },
    activateAuthRefresh: refresh => {
      window.sessionStorage.setItem('refresh', refresh)
      setIsAuth(true)
    },
    removeAuth: () => {
      setIsAuth(false)
    },
    loadingBanner,
    setLoadingBanner
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default {
  Provider,
  Consumer: Context.Consumer
}
