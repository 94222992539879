import React from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'

import LiveViewBanner from './LiveViewBanner'

const useStyles = makeStyles(theme => ({
  slide: {
    marginBottom: 5,
    padding: 15,
    minHeight: 110,
    color: '#fff'
  },
  slide1: {
    backgroundColor: '#6AC0FF'
  }
}))

export default function SwipeableDiv ({ banners }) {
  const classes = useStyles()
  return (
    <SwipeableViews enableMouseEvents>
      {banners.length > 0
        ? banners.map(banner =>
          <div key={banner.notif_id} style={{ height: 110 }}>
            <LiveViewBanner banner={banner} preview />
          </div>
        )
        : <div className={cx(classes.slide, classes.slide1)}>Aun no hay anuncios cargados</div>
      }
    </SwipeableViews>
  )
}
