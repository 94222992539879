import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Loader from './Loader'

const useStyles = makeStyles(theme => ({
  pageLoading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 350,
    width: '100%'
  }
}))

export default function PageLoading () {
  const classes = useStyles()
  return (
    <div className={classes.pageLoading}>
      <Loader />
    </div>
  )
}
