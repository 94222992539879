import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import logo from '../assets/images/marca_bambu.png'
import image from '../assets/images/background-login.jpg'

import { Context } from '../Context'
import PageLoading from '../components/PageLoading'

import { userActions } from '../actions/userActions.js'

const useStyles = makeStyles(theme => ({
  imageBackground: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    minWidth: '920px',
    minHeight: '640px',
    opacity: '0.55'
  },
  contenido: {
    position: 'relative',
    minWidth: '920px',
    minHeight: '640px',
    display: 'flex',
    alignItems: 'center',
    padding: 25
  },
  root: {
    padding: theme.spacing(3, 2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '50%'
  }
}))

export default function LoginPage () {
  const classes = useStyles()
  const { activateAuth, activateAuthRefresh } = useContext(Context)
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    cuit: 0
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const [errors, setErrors] = React.useState(null)

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const resp = await userActions.login(values)
      await setIsLoading(false)
      await activateAuthRefresh(resp.refresh)
      await activateAuth(resp.access)
    } catch (error) {
      setIsLoading(false)
      setErrors(true)
    }
  }

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <React.Fragment>
      <div className={classes.imageBackground} />
      <div className={classes.contenido}>
        <Grid container>
          <Grid item xs={6}>
            <Paper className={classes.root}>
              <Grid container justify='center'>
                <img src={logo} alt='logo' style={{ height: '130px', marginBottom: 15 }} />
              </Grid>
              <Grid container justify='flex-start'>
                <Typography component='h1' variant='h5'>
                  Iniciar Sesíon
                </Typography>
              </Grid>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='username'
                  label='Nombre de usuario'
                  name='username'
                  autoComplete='username'
                  autoFocus
                  values={values.username}
                  onChange={handleChange('username')}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  values={values.password}
                  onChange={handleChange('password')}
                />
                <Grid container justify='center'>
                  { errors
                    ? <Typography
                      component='span'
                      variant='body2'
                      style={{ fontWeight: 500, color: '#f24040' }}
                      color='textSecondary' gutterBottom
                    >
                      Su nombre de usuario o contraseña es incorrecta.&nbsp;
                      Si no recuerda su contraseña, &nbsp;
                      <Link href='#' variant='body2'>
                        {'reiníciela ahora.'}
                      </Link>
                    </Typography>
                    : null
                  }
                  <Button
                    type='submit'
                    size='large'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                  >
                    Iniciar
                  </Button>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
