import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  pageError: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%'
  }
}))

export default function PageError (props) {
  const classes = useStyles()
  return <div className={classes.pageError}>{props.error.message}</div>
}
