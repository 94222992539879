import React from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import ListItem from '@material-ui/core/ListItem'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import HelpIcon from '@material-ui/icons/Help'

import { NOTIF_PUB_TEXTO, NOTIF_PUB_TEXTO_IMAGEN, NOTIF_PUB_IMAGEN } from '../consts/'

const useStyles = makeStyles(theme => ({
  exampleContainer: {
    marginTop: 7,
    marginBottom: 7
  },
  helpContainer: {
    textAlign: 'center',
    paddingTop: 12,
    paddingLeft: 12
  },
  card: {
    margin: 'auto',
    height: 150,
    maxWidth: 400,
    minWidth: 400,
    backgroundColor: '#1211117a'
  },
  cardBackgroundImage: {
    backgroundImage: 'url(https://os.alipayobjects.com/rmsportal/IhCNTqPpLeTNnwr.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  cardContent: {
    padding: '16px !important',
    height: '100%',
    width: '100%'
  },
  listItem: {
    padding: '0px !important',
    display: 'block',
    height: '100%',
    width: '100%'
  },
  skeleton: {
    backgroundColor: 'rgba(255, 255, 255, 0.29)',
    margin: 'auto',
    marginBottom: 7
  },
  skeletonHeader: {
    backgroundColor: 'rgba(255, 255, 255, 0.29)',
    margin: 'auto',
    marginBottom: 25
  },
  iconHelp: {
    fontSize: '1em'
  }
}))

export default function TiposPub (props) {
  const classes = useStyles()

  const [state, setState] = React.useState({
    textImage: false,
    image: false,
    text: false
  })

  const handleClick = id => {
    props.setTipoPub(parseInt(id))
    props.setNewBanner({ ...props.newBanner, tipo_notif: parseInt(id) })
    // Indica el tipo de publicidad que se va a  cargar en NewPub
    if (id === `${NOTIF_PUB_TEXTO_IMAGEN}`) {
      setState({ textImage: true, image: false, text: false })
    }
    if (id === `${NOTIF_PUB_IMAGEN}`) {
      setState({ textImage: false, image: true, text: false })
    }
    if (id === `${NOTIF_PUB_TEXTO}`) {
      setState({ textImage: false, image: false, text: true })
    }
  }

  return (
    <React.Fragment>
      <Grid container className={classes.exampleContainer}>
        <Grid item xs={12} lg={7}>
          <Card id={`${NOTIF_PUB_TEXTO_IMAGEN}`} className={cx(classes.card, classes.cardBackgroundImage)}>
            <CardContent className={classes.cardContent}>
              <ListItem className={classes.listItem} button onClick={() => handleClick(`${NOTIF_PUB_TEXTO_IMAGEN}`)}>
                <Skeleton className={classes.skeletonHeader} variant='rect' height={22} width='50%' disableAnimate />
                <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
                <Skeleton className={classes.skeleton} variant='rect' height={11} width='85%' disableAnimate />
                <Skeleton className={classes.skeleton} variant='rect' height={11} width='95%' disableAnimate />
              </ListItem>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.helpContainer}>
          <Typography color='textSecondary' align='center' gutterBottom>
            Texto e imagen
          </Typography>
          <Typography color='textSecondary' gutterBottom>
            <HelpIcon className={classes.iconHelp} /> &nbsp;
            Permite cargar anuncios con descripcion e imagen
          </Typography>
          <Switch
            checked={state.textImage}
            onChange={() => handleClick(`${NOTIF_PUB_TEXTO_IMAGEN}`)}
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>
      </Grid>
      <Divider variant='middle' />
      <Grid container className={classes.exampleContainer}>
        <Grid item xs={12} lg={7}>
          <Card id={`${NOTIF_PUB_IMAGEN}`} className={cx(classes.card, classes.cardBackgroundImage)}>
            <CardContent className={classes.cardContent}>
              <ListItem className={classes.listItem} button onClick={() => handleClick(`${NOTIF_PUB_IMAGEN}`)} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.helpContainer}>
          <Typography color='textSecondary' align='center' gutterBottom>
            Solo imagen
          </Typography>
          <Typography color='textSecondary' gutterBottom>
            <HelpIcon className={classes.iconHelp} /> &nbsp;
            Permite cargar anuncios en imagen.
          </Typography>
          <Typography
            component='span'
            variant='body2'
            style={{ display: 'inherit', fontWeight: 300, color: '#f24040' }}
            color='textSecondary' gutterBottom
          >
            Importante: el espacio publicitario dentro de la app es
            rectangular.
          </Typography>
          <Switch
            checked={state.image}
            onChange={() => handleClick(`${NOTIF_PUB_IMAGEN}`)}
            value='image'
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>
      </Grid>
      <Divider variant='middle' />
      <Grid container className={classes.exampleContainer}>
        <Grid item xs={12} lg={7}>
          <Card id={`${NOTIF_PUB_TEXTO}`} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <ListItem className={classes.listItem} button onClick={() => handleClick(`${NOTIF_PUB_TEXTO}`)}>
                <Skeleton className={classes.skeletonHeader} variant='rect' height={22} width='50%' disableAnimate />
                <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
                <Skeleton className={classes.skeleton} variant='rect' height={11} width='85%' disableAnimate />
                <Skeleton className={classes.skeleton} variant='rect' height={11} width='95%' disableAnimate />
              </ListItem>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.helpContainer}>
          <Typography color='textSecondary' align='center' gutterBottom>
            Solo texto
          </Typography>
          <Typography color='textSecondary' gutterBottom>
            <HelpIcon className={classes.iconHelp} /> &nbsp;
            Anuncios sin imagenes, solo texto
          </Typography>
          <Typography
            component='span'
            variant='body2'
            style={{ display: 'inherit', fontWeight: 300, color: '#f24040' }}
            color='textSecondary' gutterBottom
          >
            Importante: Podras editar el color de fondo.
          </Typography>
          <Switch
            checked={state.text}
            onChange={() => handleClick(`${NOTIF_PUB_TEXTO}`)}
            value='text'
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
