import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import InsertChart from '@material-ui/icons/InsertChart'

import SwipeableDiv from './SwipeableDiv'

const useStyles = makeStyles(theme => ({
  smartphone: {
    position: 'relative',
    width: 300,
    height: 540,
    border: '16px black solid',
    borderTopWidth: 60,
    borderBottomWidth: 60,
    borderRadius: 36,
    '&:before': {
      content: 'close-quote',
      display: 'block',
      width: 60,
      height: 5,
      position: 'absolute',
      top: -30,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: 10
    },
    '&:after': {
      content: 'close-quote',
      display: 'block',
      width: 35,
      height: 35,
      position: 'absolute',
      left: '50%',
      bottom: -65,
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '50%'
    }
  },
  content: {
    margin: 5
  },
  root: {
    flexGrow: 1,
    maxHeight: 34,
    minHeight: 34
  },
  title: {
    flexGrow: 1,
    fontSize: '1em',
    textAlign: 'center'
  },
  card: {
    height: 84,
    padding: '5px !important',
    width: '100%',
    backgroundColor: '#c5b6b67a'
  },
  skeleton: {
    backgroundColor: 'rgba(158, 158, 158, 0.29)',
    marginBottom: 5
  },
  icon: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingRight: 7
  }
}))
export default function PreviewBanner ({ children, banners }) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.smartphone}>
        <AppBar position='static'className={classes.root} >
          <Toolbar className={classes.root}>
            <IconButton size='small' edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
                Bambú Online Banking
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Card style={{ marginBottom: 5 }} >
            <CardContent className={classes.card}>
              <Typography
                align='center'
                component='span'
                variant='body2'
                style={{ display: 'inherit', fontWeight: 900 }}
              >
              Cuentas
              </Typography>
              <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
              <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
              <Grid container justify='flex-end'>
                <Button size='small' >ver mas</Button>
              </Grid>
            </CardContent>
          </Card>
          {children
            ? <div style={{ height: 110 }}>
              {React.cloneElement(children, { preview: true })}
            </div>
            : <SwipeableDiv banners={banners} />
          }
          <Card style={{ marginBottom: 5 }} >
            <CardContent className={classes.card}>
              <Typography
                align='center'
                component='span'
                variant='body2'
                style={{ display: 'inherit', fontWeight: 900 }}
              >
              Prestamos
              </Typography>
              <Grid container>
                <Grid item xs={10}>
                  <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
                  <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
                </Grid>
                <Grid item xs={2} className={classes.icon}>
                  <MonetizationOn style={{ fontSize: '2.3em' }} />
                </Grid>
              </Grid>
              <Grid container justify='flex-end'>
                <Button size='small' >ver mas</Button>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 5 }} >
            <CardContent className={classes.card}>
              <Typography
                align='center'
                component='span'
                variant='body2'
                style={{ display: 'inherit', fontWeight: 900 }}
              >
              Inversiones
              </Typography>
              <Grid container>
                <Grid item xs={10}>
                  <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
                  <Skeleton className={classes.skeleton} variant='rect' height={11} disableAnimate />
                </Grid>
                <Grid item xs={2} className={classes.icon}>
                  <InsertChart style={{ fontSize: '2.3em' }} />
                </Grid>
              </Grid>
              <Grid container justify='flex-end'>
                <Button size='small' >ver mas</Button>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}
