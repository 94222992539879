import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import InsertChart from '@material-ui/icons/InsertChart'

import SwipeableDiv from './SwipeableDiv'

const useStyles = makeStyles(theme => ({
  laptop: {
    left: '8%',
    position: 'absolute',
    width: 748,
    height: 400,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: '24px 24px 80px',
    backgroundColor: 'black',
    '&:after': {
      content: 'close-quote',
      display: 'block',
      position: 'absolute',
      width: 841,
      height: 40,
      margin: '27px 0 0 -70px',
      background: 'black',
      borderRadius: 6
    },
    '&:before': {
      content: 'close-quote',
      display: 'block',
      position: 'absolute',
      width: 250,
      height: 25,
      bottom: -110,
      left: '32.2%',
      background: '#f1f1f1',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      zIndex: 1
    }
  },
  contentLaptop: {
    backgroundColor: '#fafafa',
    width: 700,
    height: 350,
    overflow: 'hidden',
    border: 'none'
  },
  content: {
    margin: 5
  },
  root: {
    flexGrow: 1,
    maxHeight: 34,
    minHeight: 34
  },
  title: {
    flexGrow: 1,
    fontSize: '1em',
    textAlign: 'center'
  },
  card: {
    height: 97,
    padding: '5px !important',
    width: '100%',
    backgroundColor: '#c5b6b67a'
  },
  skeleton: {
    backgroundColor: 'rgba(158, 158, 158, 0.29)',
    marginBottom: 5
  },
  icon: {
    justifyContent: 'center',
    display: 'flex',
    paddingRight: 7
  }
}))

export default function PreviewLaptop ({ children, banners }) {
  const classes = useStyles()
  return (
    <div className={classes.laptop}>
      <div className={classes.contentLaptop}>
        <AppBar position='static'className={classes.root} >
          <Toolbar className={classes.root}>
            <IconButton size='small' edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
                Bambú Online Banking
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Grid container>
            <Grid item xs={8}>
              <Card style={{ marginBottom: 7, marginRight: 3 }} >
                <CardContent className={classes.card}>
                  <Typography
                    align='center'
                    component='span'
                    variant='body2'
                    style={{ display: 'inherit', fontWeight: 900, fontSize: '1.2em' }}
                  >
                    Cuentas
                  </Typography>
                  <Skeleton className={classes.skeleton} variant='rect' height={15} disableAnimate />
                  <Skeleton className={classes.skeleton} variant='rect' height={15} disableAnimate />
                  <Grid container justify='flex-end'>
                    <Button size='small' >ver mas</Button>
                  </Grid>
                </CardContent>
              </Card>
              <Card style={{ marginBottom: 7, marginRight: 3 }} >
                <CardContent className={classes.card}>
                  <Typography
                    align='center'
                    component='span'
                    variant='body2'
                    style={{ display: 'inherit', fontWeight: 900, fontSize: '1.2em' }}
                  >
                    Prestamos
                  </Typography>
                  <Grid container>
                    <Grid item xs={10}>
                      <Skeleton className={classes.skeleton} variant='rect' height={15} disableAnimate />
                      <Skeleton className={classes.skeleton} variant='rect' height={15} disableAnimate />
                    </Grid>
                    <Grid item xs={2} className={classes.icon}>
                      <MonetizationOn style={{ fontSize: '2.3em' }} />
                    </Grid>
                  </Grid>
                  <Grid container justify='flex-end'>
                    <Button size='small' >ver mas</Button>
                  </Grid>
                </CardContent>
              </Card>
              <Card style={{ marginBottom: 7, marginRight: 3 }} >
                <CardContent className={classes.card}>
                  <Typography
                    align='center'
                    component='span'
                    variant='body2'
                    style={{ display: 'inherit', fontWeight: 900, fontSize: '1.2em' }}
                  >
                    Inversiones
                  </Typography>
                  <Grid container>
                    <Grid item xs={10}>
                      <Skeleton className={classes.skeleton} variant='rect' height={15} disableAnimate />
                      <Skeleton className={classes.skeleton} variant='rect' height={15} disableAnimate />
                    </Grid>
                    <Grid item xs={2} className={classes.icon}>
                      <InsertChart style={{ fontSize: '2.3em' }} />
                    </Grid>
                  </Grid>
                  <Grid container justify='flex-end'>
                    <Button size='small' >ver mas</Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              {children
                ? <div style={{ height: 110 }}>
                  {React.cloneElement(children, { preview: true })}
                </div>
                : <SwipeableDiv banners={banners} />
              }
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
