import { useEffect, useState, useContext } from 'react'

import { Context } from '../Context'

import { userActions } from '../actions/userActions'
import { bannersActions } from '../actions/bannersActions'

export const useBannersData = () => {
  const { removeAuth } = useContext(Context)
  const [banners, setBannersData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(function () {
    setLoading(true)
    async function fetchData () {
      if (await userActions.refreshToken()) {
        try {
          const data = await bannersActions.list()
          if (banners.length !== data.length) {
            setBannersData(data)
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          setError(error)
        }
      } else {
        window.alert('error refresh')
        setLoading(false)
        removeAuth()
      }
    }

    fetchData()
  }, [banners, removeAuth])

  return { error, loading, banners, setBannersData }
}
