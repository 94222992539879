import React, { useContext, Suspense } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from '../theme'

import Navigator from './Navigator'
// import Home from '../pages/Home'
import Login from '../pages/LoginPage'
import Publicidades from '../pages/Publicidades'
import NotFound from '../pages/NotFound'

import { Context } from '../Context'

function App () {
  const { isAuth, removeAuth } = useContext(Context)

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={
      props => isAuth ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
    } />
  )

  return (
    <Suspense fallback={<div />}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route exact path='/'
              render={() =>
                isAuth ? <Redirect to='/publicidades' /> : <Redirect to='/login' />
              }
            />
            <Route exact path='/login'>
              {!isAuth ? <Login /> : <Redirect to='/publicidades' />}
            </Route>
            <Route exact path='/logout' render={() => {
              removeAuth()
              return <Redirect to='/login' />
            }}
            />
            <Route path='/not-found' component={NotFound} />
            <Navigator>
              <Switch>
                {/* <PrivateRoute exact path='/home' component={Home} /> */}
                <PrivateRoute exact path='/publicidades' component={Publicidades} />
                <Redirect to='/not-found' />
              </Switch>
            </Navigator>
          </Switch>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
