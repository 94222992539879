import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import LiveViewBanner from './LiveViewBanner'
import { EditorBanner } from './EditorBanner'
import FullScreenDialog from './FullScreenDialog'
import PreviewLaptop from './PreviewLaptop'
import PreviewSmartPhone from './PreviewSmartphone'

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  helperText: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
}))

export default function NewPubForm (props) {
  const classes = useStyles()
  const { tipoPub, newBanner, setNewBanner, checkValidForm } = props
  const [openDialog, setopenDialog] = React.useState(false)
  const LiveBanner = <LiveViewBanner
    tipoPub={tipoPub}
    banner={newBanner}
    setopenDialog={setopenDialog}
  />

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item xs={8} >
          <EditorBanner
            checkValidForm={checkValidForm}
            tipoPub={tipoPub}
            newBanner={newBanner}
            setNewBanner={setNewBanner}
          />
        </Grid>
        <Grid item xs={4} style={{ height: 150 }} >
          {LiveBanner}
          <Grid container justify='center' style={{ marginTop: 50 }}>
            <Button
              variant='outlined'
              component='span'
              color='primary'
              onClick={() => setopenDialog(true)}
            >
              Generar vista previa
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <FullScreenDialog
        titleDialog='Vista Previa'
        openDialog={openDialog}
        setopenDialog={setopenDialog}
        helperText={`Ésta vista es un aproximado de como se verá en la app`}
      >
        <Grid container>
          <Grid item xs={8} className={classes.gridItem}>
            <PreviewLaptop>
              {LiveBanner}
            </PreviewLaptop>
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <PreviewSmartPhone>
              {LiveBanner}
            </PreviewSmartPhone>
          </Grid>
        </Grid>
      </FullScreenDialog>
    </React.Fragment>
  )
}
