import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import HelpIcon from '@material-ui/icons/Help'

import LiveViewBanner from './LiveViewBanner'

import { bannersActions } from '../actions/bannersActions'

const useStyles = makeStyles(theme => ({
  helperText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconHelp: {
    fontSize: '1em'
  }
}))

const getListStyle = isDraggingOver => ({
  marginTop: 5,
  background: isDraggingOver ? 'lightblue' : '#fafafa',
  width: '100%',
  maxHeight: 450,
  overflow: 'auto'
})

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  background: '#fafafa',
  userSelect: 'none',
  border: isDragging ? '5px solid #01A0C6' : null,
  borderRadius: '3px',
  margin: `0 0 ${5}px 0`,
  height: 'auto'
})

const reorder = (list, idBanner, startIndex, endIndex, banner, setNewBanner) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  const reorder = result.map((item, index) => {
    if (!item.notif_id) {
      setNewBanner({ ...banner, orden: index + 1 })
    }
    return { ...item, orden: index + 1 }
  })
  return reorder
}

export default function BannerListDraggable (props) {
  const { banners, setBannersData, newBanner, setNewBanner, step } = props

  const [bannersDraggables, setBannersDraggables] = useState(() => {
    const data = []
    if (newBanner) {
      banners.map(anuncio => (
        anuncio.activo && data.push(anuncio)
      ))
      data.push(newBanner)
      return data
    } else {
      banners.map(anuncio => (
        anuncio.activo && data.push(anuncio)
      ))
      return data
    }
  })

  const classes = useStyles()

  const onDragEnd = async result => {
    if (!result.destination) {
      return null
    }
    const items = reorder(
      bannersDraggables,
      result.draggableId,
      result.source.index,
      result.destination.index,
      newBanner,
      setNewBanner
    )
    setBannersDraggables(items)
    if (!newBanner) {
      await Promise.all(items.map(async item => {
        await bannersActions.updateBanner({ ...item, style: JSON.stringify(item.style) })
      }))
      setBannersData([])
    } else {
      items.map(item => (
        item.notif_id &&
          setBannersData(banners => (
            banners.map(banner => (banner.notif_id === item.notif_id
              ? Object.assign(banner, { orden: item.orden })
              : banner
            ))
          ))
      ))
    }
  }

  return (
    bannersDraggables.length > 0
      ? <React.Fragment>
        <Typography color='textSecondary' variant='subtitle1' component='div' className={classes.helperText}>
        Anuncios Activos
        </Typography>
        <Divider variant='middle' />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {bannersDraggables.map((anuncio, index) => (
                  <Draggable key={anuncio.notif_id} draggableId={`${anuncio.notif_id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <LiveViewBanner banner={anuncio} step={step} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Divider variant='middle' />
        <Typography color='textSecondary' component='div' className={classes.helperText}>
          <HelpIcon className={classes.iconHelp} />&nbsp;
          Arrastra para reordenar.
        </Typography>
      </React.Fragment>
      : 'Parece que no tienes anuncios creados.'
  )
}
