import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'

import Context from './Context'

import registerServiceWorker from './registerServiceWorker'

registerServiceWorker()

ReactDOM.render(
  <Context.Provider>
    <App />
  </Context.Provider>,
  document.getElementById('root')
)
