import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

import CloseIcon from '@material-ui/icons/Close'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  container: {
    margin: 5
  },
  helperText: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function FullScreenDialog (props) {
  const classes = useStyles()
  const { setopenDialog, openDialog, titleDialog, helperText } = props

  const handleClose = () => {
    setopenDialog(false)
  }

  return (
    <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            {titleDialog}
          </Typography>
          <Button autoFocus color='inherit' onClick={handleClose}>
            Ok
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        {helperText
          ? <Typography
            color='error'
            align='center'
            component='span'
            variant='body2'
            className={classes.helperText}
          >
            <WarningRoundedIcon className={classes.iconHelp} /> &nbsp; {helperText}
          </Typography>
          : null
        }
        {props.children}
      </div>
    </Dialog>
  )
}
