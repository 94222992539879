/* eslint-disable camelcase */
import React from 'react'

import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import BannerAnim, { Element } from 'rc-banner-anim'
import TweenOne from 'rc-tween-one'
import MUIRichTextEditor from 'mui-rte'

import { NOTIF_PUB_TEXTO, NOTIF_PUB_IMAGEN } from '../consts/'

const BgElement = Element.BgElement

const useStyles = makeStyles(theme => ({
  bannerUser: {
    height: '100%'
  },
  bannerUserElem: {
    margin: 'auto',
    textAlign: 'center',
    color: '#fff',
    position: 'relative !important',
    overflow: 'hidden',
    height: '100%',
    minHeight: 150,
    maxWidth: 400
  },
  bg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden'
  }
}))

export default function LiveViewBanner (props) {
  const classes = useStyles()
  const { preview, step,
    banner: { imagen, imagen_url, titulo, contenido, estilos }
  } = props
  const style = typeof estilos === 'string' ? JSON.parse(estilos) : estilos
  const tipoPub = props.tipoPub ? props.tipoPub : props.banner.tipo_notif
  const [titleExample] = React.useState(() => {
    if (tipoPub !== NOTIF_PUB_IMAGEN) {
      const titleMarkup = '<b>Título del anuncio</b>'
      const titleHTML = convertFromHTML(titleMarkup)
      const state = ContentState.createFromBlockArray(titleHTML.contentBlocks, titleHTML.entityMap)
      const content = JSON.stringify(convertToRaw(state))
      return content
    }
    return null
  })
  const [contentExample] = React.useState(() => {
    if (tipoPub !== NOTIF_PUB_IMAGEN) {
      const contentMarkup = `<b>Edita el contenido</b><i> del anuncio...</i> Siéntete libre.<br />
      ...incluso agrega <a href="">links</a> si los necesitas!
      <br/><i>Solo ten encuenta el <b>espacio disponible</b></i>`
      const contentHTML = convertFromHTML(contentMarkup)
      const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
      const content = JSON.stringify(convertToRaw(state))
      return content
    }
    return null
  })
  const imgDefault = tipoPub === NOTIF_PUB_TEXTO ? null : 'url(https://os.alipayobjects.com/rmsportal/IhCNTqPpLeTNnwr.jpg)'
  return (
    <BannerAnim prefixCls={classes.bannerUser} >
      <Element prefixCls={classes.bannerUserElem}
        key='0'
        style={{ minHeight: preview ? '100%' : null }}
      >
        <BgElement
          key='bg'
          className={classes.bg}
          style={{
            backgroundImage: imagen ? `url(${imagen})` : imagen_url ? `url(${imagen_url})` : imgDefault,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: style.background || '#1211117a'
          }}
        />
        <TweenOne
          className={classes.bannerUserTitle}
          animation={{ y: 30, opacity: 0, type: 'from' }}
        >
          <Typography variant='h6' component='div' align='center'
            style={{ color: style.titulo, fontSize: preview ? '1rem' : null }}
          >
            <MUIRichTextEditor
              value={step ? titulo : titulo || titleExample}
              readOnly
              controls={[]}
              inheritFontSize
            />
          </Typography>
        </TweenOne>
        <TweenOne animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}>
          <Typography variant='body1' component='div' align='center'
            style={{ color: style.contenido, fontSize: preview ? '0.65rem' : null }}
          >
            <MUIRichTextEditor
              value={contenido || contentExample}
              readOnly
              controls={[]}
              inheritFontSize
            />
          </Typography>
        </TweenOne>
      </Element>
    </BannerAnim>
  )
}
