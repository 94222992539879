import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// import PageLoading from '../components/PageLoading'
import PageError from '../components/PageError'
import NewPubStepper from '../components/NewPubStepper'
import { ListPub } from '../components/ListPub'
import ShowPub from '../components/ShowPub'

import { useBannersData } from '../hooks/useBannersData'

export default function Publicidades () {
  const { loading, banners, error, setBannersData } = useBannersData()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  if (error) {
    return <PageError error={error} />
  }

  return (
    <div style={{ width: '100%' }}>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          aria-label='full width tabs example'
        >
          <Tab label='Todos mis anuncios' {...a11yProps(0)} />
          <Tab label='Nuevo anuncio' {...a11yProps(1)} />
          <Tab label='Vista previa' {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} >
        <ListPub banners={banners} setBannersData={setBannersData} loading={loading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NewPubStepper banners={banners} setBannersData={setBannersData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ShowPub banners={banners} />
      </TabPanel>
    </div>
  )
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box style={{ marginTop: 10 }}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}
