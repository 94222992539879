import { callApi } from './userActions'
const CAKTUS_APP = process.env.REACT_APP_TOKEN

export const bannersActions = {
  async list () {
    const resp = await callApi('api/v1/publicidades/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'caktus-ent-id': 1,
        'CAKTUS-APP-TOKEN': CAKTUS_APP,
        Authorization: `JWT ${window.sessionStorage.token}`
      }
    })
    if (resp.ok) {
      return resp.data
    } else {
      throw new Error(`400: ${resp.data.detail}`)
    }
  },
  async create (newBanner) {
    const resp = await callApi('api/v1/publicidades/', {
      method: 'POST',
      body: JSON.stringify({ ...newBanner, estilos: JSON.stringify(newBanner.estilos) }),
      headers: {
        'Content-Type': 'application/json',
        'caktus-ent-id': 1,
        'CAKTUS-APP-TOKEN': CAKTUS_APP,
        Authorization: `JWT ${window.sessionStorage.token}`
      }
    })
    return { ok: resp.ok, notif_id: resp.data.notif_id }
  },
  async updateBanner (banner) {
    if (banner.notif_id) {
      const resp = await callApi(`api/v1/publicidades/${banner.notif_id}/`, {
        method: 'PATCH',
        // body: JSON.stringify({ ...banner, style: JSON.stringify(banner.style) }),
        body: JSON.stringify(banner),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${window.sessionStorage.token}`,
          'caktus-ent-id': '1',
          'CAKTUS-APP-TOKEN': CAKTUS_APP
        }
      })
      return resp.ok
    }
  },
  async deleteBanner (bannerId) {
    const resp = await callApi(`api/v1/publicidades/${bannerId}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${window.sessionStorage.token}`,
        'caktus-ent-id': '1',
        'CAKTUS-APP-TOKEN': CAKTUS_APP
      }
    })
    return resp.ok
  }
}
