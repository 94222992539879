import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import PreviewLaptop from '../components/PreviewLaptop'
import PreviewSmartPhone from '../components/PreviewSmartphone'

const useStyles = makeStyles(theme => ({
  gridItem: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export default function ShowPub ({ banners }) {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={8} className={classes.gridItem}>
        <PreviewLaptop banners={banners} />
      </Grid>
      <Grid item xs={4} className={classes.gridItem}>
        <PreviewSmartPhone banners={banners} />
      </Grid>
    </Grid>
  )
}
