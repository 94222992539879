const BASE_URL = process.env.REACT_APP_API_URL
const CAKTUS_APP = process.env.REACT_APP_TOKEN

export async function callApi (endpoint, options = {}) {
  const url = BASE_URL + endpoint
  const response = await window.fetch(url, options)
  if (options.method !== 'DELETE') {
    const data = await response.json()
    return { ok: response.ok, data: data }
  }
  return response
}

export const userActions = {
  async login (values) {
    const resp = await callApi('auth/login/', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        'CAKTUS-APP-TOKEN': CAKTUS_APP
      }
    })
    if (resp.ok) {
      return resp.data
    } else {
      throw new Error(`400: ${resp.data.non_field_errors}`)
    }
  },
  async refreshToken () {
    const resp = await callApi('auth/token-refresh/', {
      method: 'POST',
      body: JSON.stringify({ refresh: window.sessionStorage.refresh }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!resp.ok) {
      return false
    } else {
      return resp
    }
  }
  // verifToken () {
  //   //
  //   return callApi(`/badges`, {
  //     method: 'POST',
  //     body: JSON.stringify(badge)
  //   })
  // },
  // refreshToken () {
  //   return callApi(`/badges/${badgeId}`, {
  //     method: 'PUT',
  //     body: JSON.stringify(updates)
  //   })
  // }

}

// export default actions
