import React from 'react'

import moment from 'moment'

import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'
import { ChromePicker } from 'react-color'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import InvertColorsIcon from '@material-ui/icons/InvertColors'
import ColorLensTwoToneIcon from '@material-ui/icons/ColorLensTwoTone'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import { NOTIF_PUB_TEXTO, NOTIF_PUB_TEXTO_IMAGEN, NOTIF_PUB_IMAGEN } from '../consts/'

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  button: {
    margin: theme.spacing(1)
  },
  itermGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8
  },
  textField: {
    width: 300
  },
  form: {
    display: 'contents'
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    right: '54%'
  },
  cover: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  helperText: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
}))

export const EditorBanner = props => {
  const classes = useStyles()
  const { tipoPub, newBanner, setNewBanner } = props

  const [selectorColor, setSelectorColor] = React.useState({
    titulo: false,
    contenido: false,
    background: false
  })

  const openSelectorColor = name => {
    setSelectorColor({ ...selectorColor, [name]: !selectorColor[name] })
  }

  const handleChangeColor = (name, color) => {
    setNewBanner({ ...newBanner, estilos: { ...newBanner.estilos, [name]: color.hex } })
  }

  const handleChange = (editorState, name) => {
    const blocks = convertToRaw(editorState.getCurrentContent())
    if (blocks.blocks[0].text.length > 0) {
      setNewBanner({ ...newBanner, [name]: JSON.stringify(blocks) })
    } else {
      setNewBanner({ ...newBanner, [name]: '' })
    }
  }

  const handleChangeImage = async () => {
    const $form = document.querySelector('#form')
    const formData = new FormData($form)
    const file = formData.get('image')
    await toBase64(file).then((base64) => {
      setNewBanner({ ...newBanner, imagen: base64 })
    })
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const handleDeleteImage = () => {
    setNewBanner({ ...newBanner, imagen: undefined })
  }

  const handleChangeTextField = (e, name) => {
    setNewBanner({ ...newBanner, [name]: e.target.value })
  }

  const onChangeDate = (date, name) => {
    if (name === 'fec_ini') {
      setNewBanner({ ...newBanner, fec_ini: moment(date).format('YYYY-MM-DD') })
    } else {
      setNewBanner({ ...newBanner, fec_fin: moment(date).format('YYYY-MM-DD') })
    }
    if (name === 'fec_fin' && date === null) {
      setNewBanner({ ...newBanner, fec_fin: null })
    }
  }

  const { estilos: { titulo, contenido, background } } = newBanner

  return (
    <div style={{ marginTop: 8 }}>
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Typography
            align='center'
            component='div'
            variant='h6'
            className={classes.helperText}
          >
            {tipoPub === NOTIF_PUB_TEXTO ? 'Banner solo texto' : null}
            {tipoPub === NOTIF_PUB_IMAGEN ? 'Banner solo imagen' : null}
            {tipoPub === NOTIF_PUB_TEXTO_IMAGEN ? 'Banner texto e imagen' : null}
          </Typography>
          <Divider variant='middle' />
        </Grid>
      </Grid>
      {tipoPub !== NOTIF_PUB_IMAGEN &&
      <Grid container>
        <Grid item xs={12} md={6}>
          { selectorColor.titulo && <div className={classes.popover}>
            <div className={classes.cover} onClick={() => openSelectorColor('titulo')} />
            <ChromePicker color={titulo} onChange={(color) => handleChangeColor('titulo', color)} />
          </div>
          }
          { selectorColor.contenido && <div className={classes.popover}>
            <div className={classes.cover} onClick={() => openSelectorColor('contenido')} />
            <ChromePicker color={contenido} onChange={(color) => handleChangeColor('contenido', color)} />
          </div>
          }
          { selectorColor.background && <div className={classes.popover}>
            <div className={classes.cover} onClick={() => openSelectorColor('background')} />
            <ChromePicker color={background} onChange={(color) => handleChangeColor('background', color)} />
          </div>
          }
          <MUIRichTextEditor
            label='Editar titulo del anuncio...'
            onChange={(editorState) => handleChange(editorState, 'titulo')}
            controls={['bold', 'italic', 'underline', 'clear', 'on-save', 'change-color']}
            customControls={[
              {
                name: 'change-color',
                icon: <InvertColorsIcon />,
                type: 'callback',
                onClick: () => openSelectorColor('titulo')
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MUIRichTextEditor
            label='Editar contenido...'
            onChange={(editorState) => handleChange(editorState, 'contenido')}
            controls={['bold', 'italic', 'underline', 'link', 'clear', 'change-color']}
            customControls={[
              {
                name: 'change-color',
                icon: <InvertColorsIcon />,
                type: 'callback',
                onClick: () => openSelectorColor('contenido')
              }
            ]}
          />
        </Grid>
      </Grid>
      }
      <Divider variant='middle' />
      { tipoPub !== NOTIF_PUB_TEXTO
        ? <Grid container>
          <form id='form' className={classes.form}>
            <Grid item xs={12} md={6} className={classes.itermGrid} >
              <input
                accept='image/*'
                className={classes.input}
                id='text-button-file'
                name='image'
                multiple
                type='file'
                onChange={handleChangeImage}
              />
              <label htmlFor='text-button-file'>
                <Button
                  variant='contained'
                  component='span'
                  color='default'
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                >
                  Seleccionar fondo
                </Button>
              </label>
              { newBanner.imagen
                ? <IconButton
                  className={classes.button}
                  aria-label='delete'
                  color='secondary'
                  onClick={handleDeleteImage}
                >
                  <DeleteIcon />
                </IconButton>
                : null
              }
            </Grid>
            <Grid item xs={12} md={6} className={classes.itermGrid}>
              <TextField
                id='outlined-basic'
                type='text'
                disabled={!!newBanner.imagen}
                value={newBanner.imagen_url}
                onChange={(e) => handleChangeTextField(e, 'imagen_url')}
                className={classes.textField}
                label='O ingresá la direccion url del fondo'
                margin='dense'
                variant='outlined'
              />
            </Grid>
          </form>
        </Grid>
        : null
      }
      { tipoPub === NOTIF_PUB_TEXTO
        ? <Grid container justify='center'>
          <Button
            variant='outlined'
            component='span'
            color='primary'
            className={classes.button}
            onClick={() => openSelectorColor('background')}
            startIcon={<ColorLensTwoToneIcon />}
          >
            Seleccionar color de fondo
          </Button>
        </Grid>
        : null
      }
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Grid container justify='space-around'>
          <KeyboardDatePicker
            variant='outlined'
            inputVariant='outlined'
            format='dd/MM/yyyy'
            margin='normal'
            label='Fecha de inicio'
            helperText='Puedes programar la fecha en la que se va a mostrar el anuncio'
            value={newBanner.fec_ini}
            minDate={newBanner.fec_ini}
            onChange={date => onChangeDate(date, 'fec_ini')}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
          <KeyboardDatePicker
            variant='outlined'
            inputVariant='outlined'
            format='dd/MM/yyyy'
            margin='normal'
            minDate={newBanner.fec_ini}
            label='Fecha de finalización'
            helperText='Puedes programar la fecha en la que se va deshabilitar el anuncio'
            value={newBanner.fec_fin}
            onChange={date => onChangeDate(date, 'fec_fin')}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid container justify='space-around'>
        <TextField
          id='outlined-multiline-static'
          label='Descripción'
          multiline
          fullWidth
          rows='2'
          margin='normal'
          variant='outlined'
          inputProps={{
            maxLength: 80
          }}
          value={newBanner.descripcion}
          helperText='Escribe una breve descripción del anuncio.'
          onChange={(e) => handleChangeTextField(e, 'descripcion')}
        />
      </Grid>
    </div>
  )
}
